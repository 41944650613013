<template>
  <v-container fluid>
    <v-row>
      <h2>{{ $t("company.associatedCompanies") }}</h2>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xs="6"
        sm="4"
        md="4"
        v-for="company in companies"
        :key="company.companyId"
      >
        <v-checkbox
          v-model="userCompanies"
          :value="company.companyId"
          :label="company.name"
          @click="toggleCompany(company.companyId)"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import CompanyRepository from "../../api/repositories/companyRepository.js";

export default {
  name: "ExternalUserSelector",

  props: {
    userId: {
      type: Number,
    },
  },

  model: {
    prop: "userId",
  },

  data() {
    return {
      userCompanies: [],
    };
  },

  computed: {
    ...mapState("companies", ["companies"]),
  },

  methods: {
    async fetch() {
      if (this.userId === undefined) {
        this.userCompanies = [];
        return;
      }

      let temp = await CompanyRepository.getAllUserCompanies(this.userId)
        .then((d) => d.data)
        .catch(() => []);
      this.userCompanies = temp.map((d) => d.companyId);
    },

    async toggleCompany(companyId) {
      if (this.userId === undefined) return;

      await CompanyRepository.toggleUserCompany(this.userId, companyId);
      await this.fetch();
    },
  },

  async created() {
    await this.fetch();
  },

  watch: {
    async userId() {
      await this.fetch();
    },
  },
};
</script>
